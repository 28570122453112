<template>
  <q-layout view="hHh Lpr lff">
    <q-page-container style="padding-top: 0px">
      <router-view :sourceLink="sourceLink" />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'PublicLayout',

  setup () {
    const sourceLink = ref('')
    return { sourceLink }
  }
}
</script>

<style>
.q-field--dense .q-field__control,
.q-field--dense .q-field__marginal {
  height: 50px;
}
</style>
